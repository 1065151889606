import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setSending(state, payload) {
    state.isSending = payload;
  },
  setForms(state, payload) {
    state.forms = payload;
  },
  setModel(state, payload) {
    state.model = payload;
  },
  setPaymentStatus(state, payload) {
    state.paymentStatus = payload;
  },
  setSession(state, payload) {
    state.session = payload;
  },
};
