import Keycloak from 'keycloak-js';

import { CONFIG, KEYCLOAK_CONFIG } from '@/constants';
import { formHandler } from '@/utils';

const initOptions = {
  url: KEYCLOAK_CONFIG.URL,
  realm: KEYCLOAK_CONFIG.REALM,
  clientId: KEYCLOAK_CONFIG.CLIENT_ID,
  onLoad: KEYCLOAK_CONFIG.ON_LOAD,
};

const keycloak = new Keycloak(initOptions);

let refreshIntervalId;

export default {
  async initApp({ commit, dispatch, rootGetters }) {
    commit('setLoading', true);
    await dispatch('setupKeycloak');
    if (keycloak.authenticated) {
      await dispatch('fetchProfile');
      await dispatch('booking/fetchAcceptedForms', null, { root: true });
      const sessionId = getSessionId(rootGetters['booking/forms']);
      if (sessionId)
        await dispatch('booking/checkPaymentStatus', sessionId, { root: true });
    }
    commit('setLoading', false);
  },
  async setupKeycloak({ commit, dispatch }) {
    try {
      await keycloak.init({
        onLoad: KEYCLOAK_CONFIG.ON_LOAD,
      });
      if (keycloak.authenticated) {
        commit('setToken', keycloak.token);
        refreshIntervalId = setInterval(async () => {
          await dispatch('refreshToken');
        }, KEYCLOAK_CONFIG.REFRESH_TOKEN_TIME * 1000);
      }
    } catch (error) {
      commit('setToken', '');
    }
  },
  async refreshToken({ commit }) {
    try {
      const response = await keycloak.updateToken(70);
      if (response) {
        commit('setToken', keycloak.token);
      }
    } catch (error) {
      commit('setToken', '');
    }
  },
  async fetchProfile({ commit }) {
    try {
      const profile = await keycloak.loadUserProfile();
      commit('setProfile', profile);
    } catch (error) {
      commit('setError', error);
    }
  },
  async logOut({ commit }) {
    clearInterval(refreshIntervalId);
    try {
      await keycloak.logout();
      commit('reset');
    } catch (error) {
      commit('setError', error);
    }
  },
};

const getSessionId = (forms) => {
  const index = forms.findIndex(
    (form) => form.model_id === CONFIG.MODEL.ID.PURCHASE,
  );
  if (index > -1) {
    const form = forms[index];
    const content = formHandler.decode(form.json_content);
    return content.purchase_session_id;
  } else {
    return '';
  }
};
