const timer = {
  countDown(startTime, expiredMessage) {
    const now = new Date().getTime();
    const distance = startTime - now;
    if (distance < 0) {
      return expiredMessage;
    }
    const hours = String(
      Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    );
    const minutes = String(
      Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
    );
    const seconds = String(Math.floor((distance % (1000 * 60)) / 1000));
    return `${format2Digits(hours)}:${format2Digits(minutes)}:${format2Digits(
      seconds,
    )}`;
  },
};

export default timer;

const format2Digits = (amount) => amount.padStart(2, '0');
