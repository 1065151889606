import { ONBOARDING, ROUTES } from '@/constants';
import { loadPage } from '../index';

const AgreementsPage = loadPage('agreements/AgreementsPage');
const AgreementsListPage = loadPage('agreements/AgreementsListPage');
const AgreementsDetailPage = loadPage('agreements/AgreementsDetailPage');

const agreementsRoute = {
  path: ROUTES.PATH.AGREEMENTS,
  component: AgreementsPage,
  meta: {
    auth: ROUTES.USER_ROLE.AUTHORIZED,
    tags: [ONBOARDING.BOOKING, ONBOARDING.COMPLETE],
    title: ROUTES.TITLE.AGREEMENTS,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.AGREEMENTS,
      component: AgreementsListPage,
      meta: {
        auth: ROUTES.USER_ROLE.AUTHORIZED,
        tags: [ONBOARDING.BOOKING, ONBOARDING.COMPLETE],
      },
    },
    {
      path: ROUTES.PATH.AGREEMENTS_LIST,
      name: ROUTES.NAME.AGREEMENTS_LIST,
      component: AgreementsListPage,
      meta: {
        auth: ROUTES.USER_ROLE.AUTHORIZED,
        tags: [ONBOARDING.BOOKING, ONBOARDING.COMPLETE],
        title: ROUTES.TITLE.AGREEMENTS_LIST,
      },
    },
    {
      path: ROUTES.PATH.AGREEMENTS_DETAIL,
      name: ROUTES.NAME.AGREEMENTS_DETAIL,
      component: AgreementsDetailPage,
      props: true,
      meta: {
        auth: ROUTES.USER_ROLE.AUTHORIZED,
        tags: [ONBOARDING.BOOKING, ONBOARDING.COMPLETE],
        title: ROUTES.TITLE.AGREEMENTS_DETAIL,
      },
    },
  ],
};

export default agreementsRoute;
