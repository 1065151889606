const STATUS = Object.freeze({
  PAYMENT: {
    PAID: 'paid',
    PROCESSING: 'processing',
    UNDEFINED: 'undefined',
    UNPAID: 'unpaid',
  },
});

export default STATUS;
