<template>
  <v-list-item-group>
    <BaseDrawerMenuItem
      :icon="mdiCreditCardOutline"
      :title="$t('titles.joinWaitingList')"
      :to="{ name: ROUTES.NAME.HOME }"
    />
    <BaseDrawerMenuItem
      :icon="mdiFileDocumentEdit"
      :title="$t('titles.agreements')"
      :to="{ name: ROUTES.NAME.AGREEMENTS }"
    />
  </v-list-item-group>
</template>

<script>
import BaseDrawerMenuItem from './BaseDrawerMenuItem.vue';

import { ROUTES } from '@/constants';
import { mdiCreditCardOutline, mdiFileDocumentEdit } from '@mdi/js';

export default {
  name: 'TheDrawerRoutes',
  components: { BaseDrawerMenuItem },
  data() {
    return {
      ROUTES,
      mdiCreditCardOutline,
      mdiFileDocumentEdit,
    };
  },
};
</script>
