import { ONBOARDING, ROUTES } from '@/constants';
import { loadPage } from '../index';

const HomePage = loadPage('HomePage');

const homeRoute = {
  path: ROUTES.PATH.HOME,
  name: ROUTES.NAME.HOME,
  component: HomePage,
  meta: {
    auth: ROUTES.USER_ROLE.AUTHORIZED,
    tags: [ONBOARDING.COMPLETE],
    title: ROUTES.TITLE.HOME,
  },
};

export default homeRoute;
