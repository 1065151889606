<template>
  <v-list dense nav>
    <the-drawer-locale />
    <v-divider class="ma-1" />
    <the-drawer-account v-if="isAuthenticated" />
    <the-drawer-signout v-if="isAuthenticated" />
    <the-drawer-routes v-if="hasAgreements" />
  </v-list>
</template>

<script>
import TheDrawerAccount from './drawer/TheDrawerAccount.vue';
import TheDrawerLocale from './drawer/TheDrawerLocale.vue';
import TheDrawerRoutes from './drawer/TheDrawerRoutes.vue';
import TheDrawerSignout from './drawer/TheDrawerSignout.vue';

import { ONBOARDING } from '@/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'TheDrawer',
  components: {
    TheDrawerAccount,
    TheDrawerLocale,
    TheDrawerRoutes,
    TheDrawerSignout,
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapGetters('booking', ['onBoardingStatus']),
    hasAgreements() {
      return (
        this.onBoardingStatus === ONBOARDING.BOOKING ||
        this.onBoardingStatus === ONBOARDING.COMPLETE
      );
    },
  },
};
</script>
