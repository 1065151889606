import { ONBOARDING, ROUTES } from '@/constants';
import { loadPage } from '../index';

const TosPage = loadPage('TosPage');

const tosRoute = {
  path: ROUTES.PATH.TOS,
  name: ROUTES.NAME.TOS,
  component: TosPage,
  meta: {
    auth: ROUTES.USER_ROLE.AUTHORIZED,
    tags: [ONBOARDING.TOS],
    title: ROUTES.TITLE.TOS,
  },
};

export default tosRoute;
