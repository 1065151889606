const labels = {
  bookingFee: 'Costo della prenotazione',
  bookNow: 'Prenota ora',
  expired: 'Terminata',
  potentialRewardsMonth: 'Potenziali ricompense mensili in termini di token',
  potentialRewardsYear: 'Potenziali ricompense annuali in termini di token',
  resendPaymentLink: 'Reinvio link per il pagamento',
  signOut: 'Esci',
  warning: 'Attenzione',
};

export default labels