const labels = {
  bookNow: 'Book now',
  bookingFee: 'Booking fee',
  expired: 'Expired',
  potentialRewardsMonth: 'Potential Monthly Rewards in Token terms',
  potentialRewardsYear: 'Potential Annual Rewards in Token terms',
  resendPaymentLink: 'Resend payment link',
  signOut: 'Sign out',
  warning: 'Warning',
};

export default labels