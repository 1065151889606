import Vue from 'vue';
import Vuex from 'vuex';
import agreements from './agreements';
import application from './application';
import booking from './booking';
import configuration from './configuration';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // root
    application,
    // namespaced
    agreements,
    booking,
    configuration,
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});
