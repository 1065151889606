import { ONBOARDING, ROUTES } from '@/constants';
import store from '@/store';

const navigationGuard = {
  async setupAuthorization() {
    if (!store.getters['isAuthenticated']) {
      await store.dispatch('initApp');
    }
    return store.getters['isAuthenticated']
      ? ROUTES.USER_ROLE.AUTHORIZED
      : ROUTES.USER_ROLE.NOT_AUTHORIZED;
  },
  getOnBoardingStatus() {
    return store.getters['booking/onBoardingStatus'];
  },
  getDefaultRoute(status) {
    switch (status) {
      case ONBOARDING.BOOKING:
        return ROUTES.PATH.BOOKING;
      case ONBOARDING.CHECKOUT_ERROR:
        return ROUTES.PATH.CHECKOUT_ERROR;
      case ONBOARDING.CHECKOUT_WAITING:
        return ROUTES.PATH.CHECKOUT_WAITING;
      case ONBOARDING.COMPLETE:
        return ROUTES.PATH.HOME;
      case ONBOARDING.TOS:
        return ROUTES.PATH.TOS;
      default:
        return ROUTES.PATH.NOT_FOUND;
    }
  },
};

export default navigationGuard;
