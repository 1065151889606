import { ONBOARDING, ROUTES } from '@/constants';
import { loadPage } from '../index';

const BookingPage = loadPage('booking/BookingPage');
const BookingIntroPage = loadPage('booking/BookingIntroPage');
const BookingSubscriptionPage = loadPage('booking/BookingSubscriptionPage');

const bookingRoute = {
  path: ROUTES.PATH.BOOKING,
  component: BookingPage,
  meta: {
    auth: ROUTES.USER_ROLE.AUTHORIZED,
    tags: [ONBOARDING.BOOKING],
    title: ROUTES.TITLE.BOOKING,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.BOOKING,
      component: BookingIntroPage,
      meta: {
        auth: ROUTES.USER_ROLE.AUTHORIZED,
        tags: [ONBOARDING.BOOKING],
      },
    },
    {
      path: ROUTES.PATH.BOOKING_INTRO,
      name: ROUTES.NAME.BOOKING_INTRO,
      component: BookingIntroPage,
      meta: {
        auth: ROUTES.USER_ROLE.AUTHORIZED,
        tags: [ONBOARDING.BOOKING],
        title: ROUTES.TITLE.BOOKING_INTRO,
      },
    },
    {
      path: ROUTES.PATH.BOOKING_SUBSCRIPTION,
      name: ROUTES.NAME.BOOKING_SUBSCRIPTION,
      component: BookingSubscriptionPage,
      meta: {
        auth: ROUTES.USER_ROLE.AUTHORIZED,
        tags: [ONBOARDING.BOOKING],
        title: ROUTES.TITLE.BOOKING_SUBSCRIPTION,
      },
    },
  ],
};

export default bookingRoute;
