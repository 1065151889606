import { CONFIG } from '@/constants';
import { formHandler, onBoardingManager } from '@/utils';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  isSending: (state) => state.isSending,
  forms: (state) => state.forms,
  model: (state) => state.model,
  paymentStatus: (state) => state.paymentStatus,
  session: (state) => state.session,
  onBoardingStatus: (state) =>
    onBoardingManager.getProgressStatus(state.forms, state.paymentStatus),
  sessionId: (state) => {
    const form = state.forms.find(
      (form) => form.model_id === CONFIG.MODEL.ID.PURCHASE,
    );
    const content = form ? formHandler.decode(form.json_content) : '';
    return content?.purchase_session_id || '';
  },
};
