import { configurationsApi } from '@/apis';
import CONFIG from '@/constants/config-constant';

export default {
  async fetchForms({ commit, rootGetters }) {
    commit('setLoading', true);
    try {
      const profile = rootGetters['profile'];
      const response = await configurationsApi.getConfigurationUserForms({
        configuration_id: CONFIG.ID,
        user_id: profile.id,
      });
      commit('setList', response.data.list);
    } catch (error) {
      commit('setError', error);
    }
    commit('setLoading', false);
  },
  async fetchFormDetail({ commit, rootGetters }, formId) {
    commit('setLoading', true);
    try {
      const profile = rootGetters['profile'];
      const response = await configurationsApi.getConfigurationFormDetail({
        configuration_id: CONFIG.ID,
        user_id: profile.id,
        form_id: formId,
      });
      commit('setDetail', response.data);
    } catch (error) {
      commit('setError', error);
    }
    commit('setLoading', false);
  },
};
