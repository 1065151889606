<template>
  <div class="parent-width">
    <youtube
      ref="youtube"
      :video-id="videoId"
      :player-vars="playerVars"
      :resize="true"
      :fitParent="true"
    ></youtube>
  </div>
</template>

<script>
export default {
  name: 'BaseVideoPlayer',
  props: {
    videoId: {
      type: String,
      required: true,
      note: 'The video to play',
    },
  },
  data: () => ({
    playerVars: {
      autoplay: 0,
    },
  }),
};
</script>

<style scoped>
.parent-width {
  width: 100%;
  max-width: 650px;
}
</style>
