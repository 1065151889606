const CONFIG = Object.freeze({
  BROWSER_STORAGE_KEYS: {
    CHECKOUT_SESSION_ID: 'riv.wallet-sessionid',
  },
  ID: 'rivcoin',
  MODEL: {
    ID: {
      PURCHASE: 'purchase_riv',
      TOS: 'tos',
    },
  },
  REFRESH_INTERVAL: 30 * 1000,
  TOKEN: {
    MAX_AMOUNT: 399,
    MONTH_RATE: 15.56,
    VALUE: 25,
    YEAR_RATE: 186.72,
  },
});

export default CONFIG;
