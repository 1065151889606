import axios from 'axios';

import APIS from '@/constants/apis-constant';

const stripeApi = {
  postCreateCheckout(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/create-checkout',
      method: 'post',
      headers,
      baseURL: APIS.STRIPE,
    });
  },
  postResendMail(data, token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/resend-mail',
      method: 'post',
      headers,
      baseURL: APIS.STRIPE,
      data: data,
    });
  },
  getSession(sessionId, token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/session',
      method: 'get',
      headers,
      baseURL: APIS.STRIPE,
      params: {
        session_id: sessionId,
      },
    });
  },
};

export default stripeApi;
