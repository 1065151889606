import axios from 'axios';

import APIS from '@/constants/apis-constant';

const configurationsApi = {
  // getConfigurationById(configuration_id, token) {
  getConfigurationById(configuration_id) {
    const headers = {
      // Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${configuration_id}`,
      method: 'get',
      headers,
      baseURL: APIS.CONFIGURATIONS,
    });
  },
  // getConfigurationAssets(configuration_id, token) {
  getConfigurationAssets(configuration_id) {
    const headers = {
      // Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${configuration_id}/assets`,
      method: 'get',
      headers,
      baseURL: APIS.CONFIGURATIONS,
    });
  },
  // getConfigurationUserForms({ configuration_id, user_id }, token) {
  getConfigurationUserForms({ configuration_id, user_id }) {
    const headers = {
      // Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${configuration_id}/user/${user_id}/forms`,
      method: 'get',
      headers,
      baseURL: APIS.CONFIGURATIONS,
    });
  },
  // getConfigurationFormDetail({ configuration_id, user_id, form_id }, token){
  getConfigurationFormDetail({ configuration_id, user_id, form_id }) {
    const headers = {
      // Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${configuration_id}/user/${user_id}/forms/${form_id}`,
      method: 'get',
      headers,
      baseURL: APIS.CONFIGURATIONS,
    });
  },
  // postConfigurationUserForms({ configuration_id, user_id, data }, token) {
  postConfigurationUserForms({ configuration_id, user_id, data }) {
    const headers = {
      // Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };
    return axios({
      url: `/${configuration_id}/user/${user_id}/forms`,
      method: 'post',
      headers,
      baseURL: APIS.CONFIGURATIONS,
      data,
    });
  },
};

export default configurationsApi;
