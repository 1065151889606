import configurationsApi from '@/apis/configurations-api';
import CONFIG from '@/constants/config-constant';

export default {
  async initConfiguration({ commit, dispatch }) {
    commit('setLoading', true);
    const requests = [dispatch('fetchAssets'), dispatch('fetchConfigurations')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchAssets({ commit }) {
    try {
      const response = await configurationsApi.getConfigurationAssets(
        CONFIG.ID,
      );
      commit('setAssets', response.data.list);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchConfiguration({ commit }) {
    try {
      const response = await configurationsApi.getConfigurationById(CONFIG.ID);
      commit('setConfig', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};
