import Vue from 'vue';
import VueRouter from 'vue-router';

import { ROUTES } from '@/constants';
import navigationGuard from './navigation-guard';
import agreementsRoute from './routes/agreements-route';
import bookingRoute from './routes/booking-route';
import checkoutRoutes from './routes/checkout-route';
import homeRoute from './routes/home-route';
import loginRoute from './routes/login-route';
import notFoundRoute from './routes/not-found-route';
import privacyRoute from './routes/privacy-route';
import tosRoute from './routes/tos-route';

Vue.use(VueRouter);

const routes = [
  {
    path: ROUTES.PATH.ROOT,
    redirect: () => ROUTES.PATH.HOME,
    meta: {
      auth: ROUTES.USER_ROLE.AUTHORIZED,
    },
  },
];

routes.push(
  ...checkoutRoutes,
  agreementsRoute,
  bookingRoute,
  homeRoute,
  loginRoute,
  privacyRoute,
  tosRoute,
  notFoundRoute,
);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _from, next) => {
  if (to.meta.auth === ROUTES.USER_ROLE.ANY) {
    next();
    return;
  }

  const userRole = await navigationGuard.setupAuthorization();

  if (userRole === ROUTES.USER_ROLE.NOT_AUTHORIZED) {
    next(ROUTES.PATH.LOGIN);
  } else {
    const status = navigationGuard.getOnBoardingStatus();
    if (to.meta.tags && to.meta.tags.indexOf(status) > -1) {
      next();
    } else {
      const route = navigationGuard.getDefaultRoute(status);
      next(route);
    }
  }
});

export default router;

export function loadPage(page) {
  return () =>
    import(/* webpackChunkName: "page-[request]" */ `@/pages/${page}.vue`);
}
