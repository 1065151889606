import Vue from 'vue';
import VueYoutube from 'vue-youtube';

import App from './App.vue';
import i18n from './locale';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import BaseVideoPLayer from '@/components/BaseVideoPlayer.vue';

Vue.component('base-video-player', BaseVideoPLayer);
Vue.use(VueYoutube);
Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
