import { configurationsApi, modelsApi, stripeApi } from '@/apis';
import { CONFIG } from '@/constants';

export default {
  async fetchModel({ commit }, model_id) {
    commit('setLoading', true);
    try {
      const response = await modelsApi.getModel(model_id);
      commit('setModel', response.data);
    } catch (error) {
      commit('setError', error);
    }
    commit('setLoading', false);
  },
  async saveForm({ commit, dispatch, rootGetters }, form) {
    try {
      const profile = rootGetters['profile'];
      await configurationsApi.postConfigurationUserForms({
        configuration_id: CONFIG.ID,
        user_id: profile.id,
        data: form,
      });
      await dispatch('fetchAcceptedForms');
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchAcceptedForms({ commit, rootGetters }) {
    try {
      const profile = rootGetters['profile'];
      const response = await configurationsApi.getConfigurationUserForms({
        configuration_id: CONFIG.ID,
        user_id: profile?.id,
      });
      const forms = response.data.list;
      commit('setForms', forms);
    } catch (error) {
      commit('setError', error);
    }
  },
  async acceptAgreement({ commit, dispatch }, data) {
    commit('setLoading', true);
    await dispatch('saveForm', data);
    commit('setLoading', false);
  },
  async createCheckout({ commit, rootGetters }) {
    commit('setLoading', true);
    const token = rootGetters['token'];
    try {
      const response = await stripeApi.postCreateCheckout(token);
      commit('setSession', response.data);
    } catch (error) {
      commit('setError', error);
    }
    commit('setLoading', false);
  },
  async checkPaymentStatus({ commit, rootGetters }, sessionId) {
    const token = rootGetters['token'];
    commit('setLoading', true);
    try {
      const response = await stripeApi.getSession(sessionId, token);
      commit('setPaymentStatus', response.data.payment_status);
    } catch (error) {
      commit('setError', error);
    }
    commit('setLoading', false);
  },
  async resendMail({ commit, rootGetters }, sessionId) {
    const token = rootGetters['token'];
    const data = {
      checkout_session_id: sessionId,
    };
    let isSent;
    commit('setSending', true);
    try {
      await stripeApi.postResendMail(data, token);
      isSent = true;
    } catch (error) {
      isSent = false;
    }
    commit('setSending', false);
    return isSent;
  },
};
