const BACKEND = process.env.VUE_APP_BACKEND;
const BASE_PATH = `${BACKEND}/api/v1`;
const STRIPE = process.env.VUE_APP_STRIPE;
const STRIPE_PATH = `${STRIPE}/v1`;

const APIS = Object.freeze({
  CONFIGURATIONS: `${BASE_PATH}/configurations`,
  FILES: `${BASE_PATH}/files`,
  MODELS: `${BASE_PATH}/models`,
  STRIPE: `${STRIPE_PATH}/stripe`,
});

export default APIS;
