import { CONFIG, ONBOARDING, STATUS } from '@/constants';

const onBoardingManager = {
  getProgressStatus(forms, paymentStatus) {
    const statusBeforePayment = checkForms(forms);
    if (statusBeforePayment !== ONBOARDING.CHECKOUT_WAITING) {
      return statusBeforePayment;
    }
    return checkPayment(paymentStatus);
  },
};

export default onBoardingManager;

const hasModel = (list, el) => list.indexOf(el) > -1;

const checkForms = (list) => {
  const purchaseModelId = CONFIG.MODEL.ID.PURCHASE;
  const tosModelId = CONFIG.MODEL.ID.TOS;
  const forms = list.map((el) => el.model_id);
  if (hasModel(forms, tosModelId) && !hasModel(forms, purchaseModelId)) {
    return ONBOARDING.BOOKING;
  } else if (hasModel(forms, tosModelId) && hasModel(forms, purchaseModelId)) {
    return ONBOARDING.CHECKOUT_WAITING;
  } else {
    return ONBOARDING.TOS;
  }
};

const checkPayment = (paymentStatus) => {
  switch (paymentStatus) {
    case STATUS.PAYMENT.PAID:
      return ONBOARDING.COMPLETE;
    case STATUS.PAYMENT.PROCESSING:
      return ONBOARDING.CHECKOUT_WAITING;
    case STATUS.PAYMENT.UNDEFINED:
      return ONBOARDING.CHECKOUT_ERROR;
    default:
      return ONBOARDING.BOOKING;
  }
};
